<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-shop-set-main"
  >
    <div class="w-aim-head">
      <div class="w-crumbs-href">
        <router-link
          tag="span"
          :to="{ path: '/' }"
        >工作台</router-link>
        <i class="el-icon-arrow-right"></i>
        <router-link
          tag="span"
          :to="{ path: '/work' }"
        >招商规划管理</router-link>
        <i class="el-icon-arrow-right"></i>
        <span class="w-white">主次力店设置</span>
      </div>
      <span
        class="add-btn"
        @click="shopSetData()"
        v-if="hasPermissionAdd"
      >添加</span>
    </div>
    <div v-if="hasPermissionShow">
      <div class="w-singed-table">
        <table>
          <thead>
            <tr>
              <td>项目</td>
              <td>面积类型</td>
              <td>主力店</td>
              <td>次主力店</td>
              <td>创建人</td>
              <td>创建时间</td>
              <td>修改人</td>
              <td>修改时间</td>
              <td
                width="120"
                v-if="hasPermissionDelete || hasPermissionEdit"
              >操作</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, inx) in dataList"
              :key="inx"
            >
              <td>
                <span
                  v-for="(item2, inx2) in item.project"
                  :key="inx2"
                >{{item2.label}}</span>
              </td>
              <td>{{item.areaType === 1 ? '使用面积' : '建筑面积'}}</td>
              <td>{{item.mainStoreStr}}</td>
              <td>{{item.secondaryStoreStr}}</td>
              <td>{{item.createUserName}}</td>
              <td>{{item.createTime}}</td>
              <td>{{item.updateUserName}}</td>
              <td>{{item.updateTime}}</td>
              <td
                class="w-main-color"
                v-if="hasPermissionDelete || hasPermissionEdit"
              >
                <span
                  @click="editData(item)"
                  v-if="hasPermissionEdit"
                >修改</span>
                <span
                  @click="deleteData(item)"
                  v-if="hasPermissionDelete"
                >删除</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="no-recommend-brand"
          style="padding: 80px; 0"
          v-if="notData"
        >
          <img src="@/assets/images/public/not-plandata.png">
          <p>暂无相关数据</p>
        </div>
      </div>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
    <el-dialog
      :visible.sync="shopSetDialog"
      custom-class="shop-set-dialog"
      width="40%"
    >
      <h2>主次力店范围值设置</h2>
      <p class="dialog-tips">可配置对应项目按对应面积类型设置主次力店的范围值</p>
      <el-form
        ref="form"
        :model="form"
        label-position="right"
      >
        <el-form-item
          label="项目"
          label-width="100px"
        >
          <div class="w-input-sty only-select">
            <el-select
              v-model="projectSelect"
              placeholder="请选择"
              filterable
              multiple
              collapse-tags
              :loading="selectLoading"
              popper-class="w-block-select-down"
              @change="selectData"
              :disabled="isEdit"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item
          label="面积类型"
          label-width="100px"
        >
          <div class="w-input-sty only-select">
            <el-select
              v-model="form.areaType"
              placeholder="请选择"
              popper-class="w-block-select-down"
            >
              <el-option
                v-for="item in areaType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item
          label="主力店"
          label-width="100px"
        >
          <div class="add-shop-data">
            <div class="w-input-sty">
              <el-select
                v-model="form.symbolMaf"
                placeholder="请选择"
                popper-class="w-block-select-down"
                style="margin-right: 10px"
              >
                <el-option
                  v-for="item in symbol"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-model.number="form.mainAreaFirst"
                type="number"
                @keydown.native="channelInputLimit"
              ></el-input>
            </div>
            <span style="margin: 0 10px; color: #FFF">并且</span>
            <div class="w-input-sty">
              <el-select
                v-model="form.symbolMas"
                placeholder="请选择"
                popper-class="w-block-select-down"
                style="margin-right: 10px"
              >
                <el-option
                  v-for="item in symbol"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-model.number="form.mainAreaSecond"
                type="number"
                @keydown.native="channelInputLimit"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="次主力店"
          label-width="100px"
        >
          <div class="add-shop-data">
            <div class="w-input-sty">
              <el-select
                v-model="form.symbolSaf"
                placeholder="请选择"
                popper-class="w-block-select-down"
                style="margin-right: 10px"
              >
                <el-option
                  v-for="item in symbol"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-model.number="form.secondaryAreaFirst"
                type="number"
                @keydown.native="channelInputLimit"
              ></el-input>
            </div>
            <span style="margin: 0 10px; color: #FFF">并且</span>
            <div class="w-input-sty">
              <el-select
                v-model="form.symbolSas"
                placeholder="请选择"
                popper-class="w-block-select-down"
                style="margin-right: 10px"
              >
                <el-option
                  v-for="item in symbol"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-model.number="form.secondaryAreaSecond"
                type="number"
                @keydown.native="channelInputLimit"
              ></el-input>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="shopSetDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitData"
        >确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="confirmDialog"
      custom-class="confirm-dialog"
      width="30%"
      top="35vh"
    >
      <p style="color: #FFF">删除后对应项目所有铺位将赋值为普通店，请确认是否删除</p>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="confirmDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitDelete"
        >确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="confirmSubmitDialog"
      custom-class="confirm-dialog"
      width="30%"
      top="35vh"
    >
      <p style="color: #FFF">提交后铺位的主力店信息将按当前设置统一赋值，请确认是否提交</p>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="confirmSubmitDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmSubmit"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      selectLoading: false,
      shopSetDialog: false,
      confirmDialog: false,
      confirmSubmitDialog: false,
      dialogType: '',
      symbol: [
        { value: 1, label: '>' },
        { value: 2, label: '<' },
        { value: 3, label: '>=' },
        { value: 4, label: '<=' }
      ],
      areaType: [
        { value: 0, label: '建筑面积' },
        { value: 1, label: '使用面积' }
      ],
      dataList: [],
      form: {
        project: [],
        areaType: 0,
        mainAreaFirst: '',
        mainAreaSecond: '',
        symbolMaf: 1,
        symbolMas: 1,
        secondaryAreaFirst: '',
        secondaryAreaSecond: '',
        symbolSaf: 1,
        symbolSas: 1
      },
      options: [],
      projectSelect: [],
      notData: false,
      isEdit: false,
      hasPermissionShow: false,
      hasPermissionAdd: false,
      hasPermissionEdit: false,
      hasPermissionDelete: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  created () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 190
    })
    if (this.userAuthorities.length) {
      this.hasPermissionShow = this.userAuthorities[0].func.indexOf('show') !== -1
      this.hasPermissionAdd = this.userAuthorities[0].func.indexOf('add') !== -1
      this.hasPermissionEdit = this.userAuthorities[0].func.indexOf('edit') !== -1
      this.hasPermissionDelete = this.userAuthorities[0].func.indexOf('del') !== -1
      if (this.hasPermissionShow) {
        this.init()
      }
    }
    this.axios.post(api.getBerthProjectList).then((res) => {
      this.options = res.data.data
      this.options.unshift({ projectName: '全部项目', id: 0 })
    })
  },
  methods: {
    init () {
      this.loading = true
      this.axios.post(api.mainSecondaryStoreConfigureList).then((res) => {
        this.loading = false
        this.dataList = []
        if (res.data.data.length === 0) {
          this.notData = true
        } else {
          this.dataList = res.data.data
          this.notData = false
        }
      })
    },
    channelInputLimit (e) {
      var key = e.key
      if (key === '+' || key === '-' || key === 'e') {
        e.returnValue = false
        return false
      }
      return true
    },
    // 选择项目
    selectData (data) {
      this.form.project = []
      if (this.projectSelect[0] === 0 && this.projectSelect.length !== 1) {
        this.projectSelect.shift()
      } else if (this.projectSelect[this.projectSelect.length - 1] === 0) {
        this.projectSelect = [0]
      }
      this.options.map((item, inx) => {
        if (this.projectSelect[0] === 0) {
          if (inx !== 0) {
            this.form.project.push({ label: item.projectName, value: item.id })
          }
        } else {
          data.map(item2 => {
            if (item.id === item2) {
              this.form.project.push({ label: item.projectName, value: item.id })
            }
          })
        }
      })
    },
    // 添加
    shopSetData () {
      this.shopSetDialog = true
      this.isEdit = false
      this.dialogType = 'add'
      this.form = this.$options.data().form
      this.projectSelect = [0]
    },
    // 编辑
    editData (item) {
      this.shopSetDialog = true
      this.isEdit = true
      this.dialogType = 'edit'
      this.form = JSON.parse(JSON.stringify(item))
      this.projectSelect = this.form.project.map(item => {
        return item.value
      })
    },
    submitData () {
      var mainSym1 = this.form.symbolMaf
      var mainSym2 = this.form.symbolMas
      var secondSym1 = this.form.symbolSaf
      var secondSym2 = this.form.symbolSas
      var mainArea1 = this.form.mainAreaFirst
      var mainArea2 = this.form.mainAreaSecond
      var secondArea1 = this.form.secondaryAreaFirst
      var secondArea2 = this.form.secondaryAreaSecond
      var checkMain = this.checkSymbolData(mainSym1, mainSym2, mainArea1, mainArea2)
      var checkSecond = this.checkSymbolData(secondSym1, secondSym2, secondArea1, secondArea2)
      if (this.projectSelect.length === 0) {
        this.$message.error('请选择项目')
        return
      }
      if (this.projectSelect[0] === 0) {
        this.form.project = []
        this.options.map((item, inx) => {
          if (inx !== 0) {
            this.form.project.push({
              label: item.projectName,
              value: item.id
            })
          }
        })
      }
      if (!checkMain || !checkSecond) {
        this.$message.error('请设置正确的区间值')
        return
      }
      if (mainArea1 === '' && mainArea2 === '' && secondArea1 === '' && secondArea2 === '') {
        this.$message.error('请设置主/次力店的区间值')
        return
      }
      this.confirmSubmitDialog = true
    },
    confirmSubmit () {
      var form = this.form
      var apiStr = ''
      if (this.dialogType === 'add') {
        apiStr = api.mainSecondaryStoreConfigure
      } else {
        apiStr = api.mainSecondaryStoreConfigureUpdate
      }
      this.loading = true
      this.axios.post(apiStr, {
        areaType: form.areaType,
        mainAreaFirst: form.mainAreaFirst,
        mainAreaSecond: form.mainAreaSecond,
        project: form.project,
        secondaryAreaFirst: form.secondaryAreaFirst,
        secondaryAreaSecond: form.secondaryAreaSecond,
        symbolMaf: form.symbolMaf,
        symbolMas: form.symbolMas,
        symbolSaf: form.symbolSaf,
        symbolSas: form.symbolSas
      }).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.confirmSubmitDialog = false
          this.shopSetDialog = false
          this.init()
        } else {
          this.confirmSubmitDialog = false
        }
      })
    },
    // 检查区间值
    checkSymbolData (symbo1, symbo2, area1, area2) {
      var check = true
      if (area1 !== '' && area2 !== '' && area1 !== null && area2 !== null) {
        if ((symbo1 === 1 || symbo1 === 3) && (symbo2 === 2 || symbo2 === 4)) {
          Number(area1) >= Number(area2) ? check = false : check = true
        } else if ((symbo1 === 2 || symbo1 === 4) && (symbo2 === 1 || symbo2 === 3)) {
          Number(area1) <= Number(area2) ? check = false : check = true
        } else if (symbo1 === symbo2 && area1 && Number(area2)) {
          check = false
        }
      }
      return check
    },
    // 删除
    deleteData (item) {
      this.confirmDialog = true
      this.form = item
    },
    submitDelete () {
      var form = this.form
      this.loading = true
      this.axios.post(api.mainSecondaryStoreConfigureDelete, {
        areaType: form.areaType,
        mainAreaFirst: form.mainAreaFirst,
        mainAreaSecond: form.mainAreaSecond,
        project: form.project,
        secondaryAreaFirst: form.secondaryAreaFirst,
        secondaryAreaSecond: form.secondaryAreaSecond,
        symbolMaf: form.symbolMaf,
        symbolMas: form.symbolMas,
        symbolSaf: form.symbolSaf,
        symbolSas: form.symbolSas
      }).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.init()
        }
        this.confirmDialog = false
      })
    }
  }
}
</script>

<style lang="stylus">
.w-shop-set-main
  .shop-set-dialog, .confirm-dialog
    background #272930
    h2
      font-size 18px
      font-weight 600
    .dialog-tips
      color #606266
      margin 5px 0 30px
    .add-shop-data, .add-shop-data .w-input-sty
      display flex
    .w-input-sty
      width 38%
      .el-input__inner
        width 100%
    .dialog-footer
      text-align center
      .el-button
        width 125px
    .only-select
      .el-select
        width 100%
    .el-form-item__label, h2
      color #FFF
</style>
<style lang="stylus" scoped>
.w-shop-set-main
  margin-top 10px
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  .w-aim-head
    display flex
    height 34px
    position relative
  .add-btn
    position absolute
    right 0
    bottom 0
    color #FFF
    font-size 12px
    padding 5px 35px
    border 1px solid #FFF
    border-radius 2px
    cursor pointer
  .w-singed-table
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    margin-top 15px
    box-sizing border-box
    padding 0 24px
    padding-bottom 35px
    table
      width 100%
      thead
        tr
          height 54px
          line-height 54px
          color #999
          font-size 12px
          td
            border-bottom 1px solid rgba(216, 216, 216, 0.1)
            padding-left 10px
      tbody
        tr
          height 47px
          line-height 47px
          color #ffffff
          font-size 12px
          &:hover td
            background-color #23252b
          td
            border none
            border-bottom 1px solid rgba(216, 216, 216, 0.1)
            line-height 24px
            padding-left 10px
            span
              margin-right 10px
              cursor pointer
            .status
              display inline-block
              width 10px
              height 10px
              border-radius 5px
              background #FFA134
              margin-right 5px
</style>
